import { PromptHistory } from '../../types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useGetPromptHistory(userId: string) {
  const url = `/api/v1/users/${userId}/prompts`;

  const request = new Request(url);

  return useAuthenticatedRequest<PromptHistory[]>(request, {
    queryKey: ['prompts'],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.prompt_queries;
    },
  });
}

export default useGetPromptHistory;
