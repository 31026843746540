import { useAuthUtils } from '../../../util';
import Links from './Links';
import { navItems } from './util';

interface Props {
  onClick: () => void;
}

export function NavbarMobile(props: Props) {
  const { logout } = useAuthUtils();

  const onLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };
  return (
    <div className="mt-6 p-6">
      <Links items={navItems} onClick={props.onClick} isMobile />
      <div
        className="focus-visible:ring-primary-500 group flex items-center rounded-lg p-4 text-base font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
        onClick={onLogout}
      >
        Logout
      </div>
    </div>
  );
}

export default NavbarMobile;