import { BreadCrumb, Container } from '../../ui-base';
import { Footer, Header } from '../../ui-elements';
import { useScrollToTopOnLoad } from '../../util';

export const AboutUs = () => {
  useScrollToTopOnLoad();
  return (
    <>
      <Header />
      <div className="xl:pt-[35px]">
        <BreadCrumb label={'About Us'} />
      </div>
      <section className="pb-[67px] pt-[15px] xl:pt-[100px]">
        <Container className="text-center">
          <span className="mb-[16px] block uppercase text-[#1E0E62]">
            About Us
          </span>
          <h3 className="mb-[24px] text-center text-2xl font-bold text-[#1E0E62] xl:text-5xl">
            Powered with AI, backed by experts
          </h3>
          <p className="mx-auto mb-8 max-w-[820px] text-[#7D7D7D] xl:text-[22px] xl:leading-[38px]">
            LawMatrix is a combination of internal legal experts, topnoch
            talents and proprietary AI technology working together to innovate,
            accelerate and bring next generation tech into legal business
            globally
          </p>
          <img className="mx-auto" src="/images/aboutus/banner.svg" alt="" />
        </Container>
      </section>
      <section className="bg-[#FBFBFF] py-[40px] xl:py-[100px]">
        <Container>
          <h3 className="mb-[24px] text-2xl font-bold text-[#1E0E62] xl:mb-[42px] xl:text-5xl">
            Our Story
          </h3>
          <p className="pb-[2rem] pr-[40px] text-[#7D7D7D] xl:pr-[8rem] xl:text-[22px] xl:leading-[38px]">
            LawMatrix co-founder - Abhishek Kushwaha,having come from a family
            background of lawyers and has seen the legal community from close in
            last 20 years has realised that modern technology has not reached to
            this community in its full potential. The lawyers are still spending
            a lot of time in tasks like legal research, drafting etc. Limited
            technology has reached them to help aid them in these tasks. In the
            Era of AI most of these task can be automated or speeded up to save
            time for lawyers and help them prepare better case to help their
            clients get justice.{' '}
          </p>
          {/* <img
            className="mx-auto my-[24px] xl:my-[42px]"
            src="/images/aboutus/story-banner.svg"
            alt=""
          /> */}
          <p className="mb-[2rem] pr-[20px] text-[#7D7D7D] xl:pr-[8rem] xl:text-[22px] xl:leading-[38px]">
            The urge to bring tech innovation into legal domain, three friends
            from college started working on the legalTech and thats how
            LawMatrix was born. Each co-founders, Ramakant Verma, Chandra Mohan
            Meena and Abhishek Kushwaha has a diverse set of expertize and has
            more than 14 years of industry experience with the compay likes
            Amazon, Walmart, ebay,traxn etc. but most importantly all of them
            has been working in AI domain from past many years.
          </p>
          <p className="mb-[2rem] pr-[20px] text-[#7D7D7D] xl:pr-[8rem] xl:text-[22px] xl:leading-[38px]">
            Co-founders together with talented team of technologist and lawyers
            are bringing AI products to revolutinize legal industry
          </p>
        </Container>
      </section>
      <section className="py-[40px] xl:py-[95px]">
        <Container className="justify-between xl:flex">
          <h3 className="mb-[1.5rem] text-5xl font-bold text-[#1E0E62]">
            Our Company
          </h3>
          <p className="text-[#7D7D7D] xl:max-w-[870px] xl:text-[22px] xl:leading-[38px]">
            Based in India's silicon valley, Bangalore, we are building worlds
            top AI
            <br className="hidden xl:block" /> solutions for legal community.
            Our first product was launched for legal Publisher
            <br className="hidden xl:block" /> helping them remove the biggest
            bottleneck in their publishing flow. Our next product is launching
            soon for the lawyers which will greatly enhance their
            <br className="hidden xl:block" /> research capability.{' '}
          </p>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default AboutUs;
