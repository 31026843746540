import { UploadDocumentForAssistant } from '../../types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useUploadDocumentForAssitant(
  userId: string,
  onSuccess: (data: UploadDocumentForAssistant) => void,
) {
  const url = `/api/v1/users/${userId}/documents`;

  const request = new Request(url, {
    method: 'POST',
  });

  return useAuthenticatedMutation<UploadDocumentForAssistant, FormData>(
    request,
    {
      onSuccess,
      getBody: (data) => data,
    },
  );
}

export default useUploadDocumentForAssitant;
