import { SavePromptRequest } from '../../types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useSavePrompt(
  userId: string,
  promptId: string,
  onSuccess: () => void,
) {
  const url = `/api/v1/users/${userId}/prompts/${promptId}`;

  const request = new Request(url, {
    method: 'PATCH',
    headers: {
      'content-type': 'application/json',
    },
  });

  return useAuthenticatedMutation<null, SavePromptRequest>(request, {
    onSuccess,
    onSuccessInvalidationKeys: [['prompts']],
  });
}

export default useSavePrompt;
