import clsx from 'clsx';
import { MouseEventHandler, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  disabled?: boolean;
  type?: 'button' | 'submit';
  title?: string;
  variant?: 'primary' | 'outline' | 'icon' | 'link';
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  size?: 'big' | 'small';
}

export const Button = (props: Props) => {
  const {
    children,
    disabled,
    type = 'button',
    title,
    variant,
    onClick,
    className,
    size,
  } = props;
  const shadowStyle =
    'shadow-[0_1px_5px_0_rgba(0,0,0,0.12),0_2px_2px_0_rgba(0,0,0,0.14),0_3px_1px_-2px_rgba(0,0,0,0.2)]';
  const commonStyle = `${shadowStyle} rounded-md ${size === 'small' ? 'px-[15px] py-1' : 'px-[15px] py-[12px]'}`;
  return (
    <>
      <button
        title={title}
        onClick={onClick}
        type={type}
        disabled={disabled}
        className={clsx(
          'inline-flex items-center font-bold',
          variant === 'primary' &&
            `bg-[#4C417E] text-white hover:bg-[#3c3268] ${commonStyle}`,
          variant === 'outline' && `bg-white text-[#1F1938] ${commonStyle}`,
          variant === 'icon' &&
            'rounded-full border-transparent p-2 text-[#4C417E] enabled:hover:bg-[#e5e2f1]',
          className || '',
          variant === 'link' &&
            'rounded border-transparent px-2 text-blue-500 enabled:hover:underline',
          disabled && 'disabled:opacity-75',
        )}
      >
        {children}
      </button>
    </>
  );
};

export default Button;
