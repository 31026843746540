import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserProfileProvider } from '../../providers';
import DashboardRoutes from './DashboardRoutes';
import Toaster from './Toaster';
import RootHeader from './header/RootHeader';
import Navbar from './navbar/Navbar';

export function DashboardRoot() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/workbench`);
  }, []);
  return (
    <UserProfileProvider>
      <RootHeader />
      <div className="flex w-full">
        <Navbar />
        <DashboardRoutes />
      </div>
      <Toaster />
    </UserProfileProvider>
  );
}
export default DashboardRoot;
