import { Footer, Header } from '../../ui-elements';
import { useScrollToTopOnLoad } from '../../util';
import { getAllSections } from './utils';

export function PrivacyRoot() {
  const allSections = getAllSections();
  useScrollToTopOnLoad();
  return (
    <>
      <Header />
      <div className="px-6 py-4 md:px-20 md:py-14">
        <h1 className="mb-8 text-center text-4xl text-[#1E0E62]">
          Privacy Policy
        </h1>
        <p className="my-4">
          Please read this Privacy Policy before using the application or
          submitting any personal information. By using Lawmatrix, it shall be
          deemed that the User are expressly consenting to be bound by the terms
          and conditions described in this Privacy Policy.
        </p>
        <div>
          {allSections.map((section, i) => (
            <div key={section.id} className="my-8">
              <div className="my-4 text-2xl font-bold">
                {i + 1}. {section.title}
              </div>
              <div>{section.content}</div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyRoot;