import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { NavItem } from '../../../types';

interface Props {
  items: NavItem[];
  onClick?: () => void;
  short?: boolean;
  isMobile?: boolean;
}

function CollapsedLinks(props: Props) {
  const { items, short, onClick = () => null, isMobile } = props;

  const desktopActiveStyle =
    'text-lawmatrix-purple border-lawmatrix-purple border-l-2 bg-[#f4f3f8] mx-1';

  const mobileActiveStyle = 'text-lawmatrix-purple text-lg';

  const activeStyle = isMobile ? mobileActiveStyle : desktopActiveStyle;

  return (
    <nav>
      <div className="space-y-1">
        {items.map((item) => (
          <NavLink
            key={item.name}
            to={item.href}
            end
            title={item.name}
            className={({ isActive }) =>
              clsx(!isActive && 'px-2 text-gray-500 hover:text-gray-700')
            }
            onClick={onClick}
          >
            {({ isActive }) => (
              <div
                className={clsx(
                  'flex flex-col items-center justify-center',
                  isActive && 'text-lawmatrix-purple',
                )}
              >
                <div
                  className={clsx(
                    isActive && activeStyle,
                    'focus-visible:ring-primary-500 group flex items-center rounded-lg p-2 text-base font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2',
                  )}
                >
                  <item.icon
                    className={clsx(
                      isActive
                        ? 'text-lawmatrix-purple'
                        : 'text-gray-500 group-hover:text-gray-700',
                      'h-6 w-6 flex-shrink-0',
                    )}
                    aria-hidden="true"
                  />
                </div>
                <div className="text-sm">{short ? '' : item.name}</div>
              </div>
            )}
          </NavLink>
        ))}
      </div>
    </nav>
  );
}

export default CollapsedLinks;
