import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  Outlet,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { AboutUs } from './pages/AboutUs/AboutUs';
import Home from './pages/Home';
import ContactUs from './pages/contact-us/ContactUs';
import DashboardRoot from './pages/dashboard/DashboardRoot';
import { PrivacyRoot } from './pages/privacy/PrivacyRoot';
import { RefundPolicy } from './pages/refund-policy/RefundPolicy';
import { TermsAndConditions } from './pages/terms-and-conditions/TermsAndConditions';
import WorkbenchRoot from './pages/workbench/WorkbenchRoot';
import { AuthProvider, HttpClientProvider } from './providers';

function Providers() {
  const queryClient = new QueryClient();
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <HttpClientProvider>
          <Outlet />
        </HttpClientProvider>
      </QueryClientProvider>
    </AuthProvider>
  );
}

function getRouter() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<Providers />}>
        <Route path="/" element={<Home />}>
          <Route element={<Home />} />
        </Route>
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/privacy" element={<PrivacyRoot />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/dashboard/*" element={<DashboardRoot />} />
        <Route path="/workbench/*" element={<WorkbenchRoot />} />
      </Route>,
    ),
  );
  return router;
}

export default getRouter;
