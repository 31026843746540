import { useMemo } from 'react';
import { useGetPromptHistory } from '../../../data-access';
import { PromptHistory as PromptHistoryType } from '../../../types';
import { LawMatrixSpinner } from '../../../ui-base';
import { useUser } from '../../../util';

interface Props {
  onSelectPrompt: (prompt: PromptHistoryType) => void;
}

export function PromptHistory(props: Props) {
  const { onSelectPrompt } = props;
  const { userId = '' } = useUser();

  const { data = [], isLoading } = useGetPromptHistory(userId);

  const sortedData = useMemo(
    () =>
      data
        .filter((item) => item.is_saved)
        .sort(
          (a, b) =>
            new Date(b.updated_at_utc).getTime() -
            new Date(a.updated_at_utc).getTime(),
        ),
    [data],
  );

  const onSelect = (prompt: PromptHistoryType) => () => onSelectPrompt(prompt);

  return (
    <div className="flex-1 rounded-md border bg-gray-100/60 p-2">
      <div className="flex justify-between py-2 text-lawmatrix-purple ">
        <span>My Prompts</span>
      </div>
      {isLoading && (
        <div className="flex h-10 w-full items-center justify-center">
          <LawMatrixSpinner />
        </div>
      )}
      <ol className="space-y-4">
        {sortedData.map((item) => (
          <li className="ml-5 list-item list-decimal" key={item.id}>
            <div
              className="hover: flex cursor-pointer flex-col rounded-md p-1 hover:border hover:bg-slate-200"
              tabIndex={1}
              onClick={onSelect(item)}
            >
              <span>{item.alias}</span>
              <span className="text-xs text-gray-500">
                (saved on {new Date(item.updated_at_utc).toLocaleString()})
              </span>
            </div>
          </li>
        ))}
      </ol>
    </div>
  );
}

export default PromptHistory;
