import { PencilSquareIcon } from '@heroicons/react/24/solid';
import { NavItem } from '../../../types';

export const navItems: NavItem[] = [
  {
    id: 'workbench',
    name: 'Assistant',
    href: '/workbench',
    icon: PencilSquareIcon,
  },
];
