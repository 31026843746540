import { XMarkIcon } from '@heroicons/react/24/outline';
import { UploadDocumentForAssistant } from '../../../types';
import { Button, Checkbox } from '../../../ui-base';

interface Props {
  allDocuments: UploadDocumentForAssistant[];
  selectedDocumentIds: string[];
  isUploading?: boolean;
  onChangeSelectedIds: (ids: string[]) => void;
}

export function UploadedDocuments(props: Props) {
  const {
    allDocuments,
    selectedDocumentIds,
    isUploading,
    onChangeSelectedIds,
  } = props;

  const onChangeSelected = (docId: string) => (checked: boolean) => {
    let selectedIds = [...selectedDocumentIds];
    if (checked) {
      selectedIds.push(docId);
    } else {
      selectedIds = selectedIds.filter((id) => id !== docId);
    }
    onChangeSelectedIds(selectedIds);
  };

  const removeFromSelected = (docId: string) => () => {
    let selectedIds = [...selectedDocumentIds];
    selectedIds = selectedIds.filter((id) => id !== docId);
    onChangeSelectedIds(selectedIds);
  };

  return (
    <div className="flex-1 rounded-md border bg-gray-100/60 p-2">
      <div className="flex justify-between py-2 text-lawmatrix-purple ">
        <span>Documents</span>
      </div>
      {isUploading && (
        <div className="mx-auto flex h-full w-20 items-center justify-center">
          <img src="/images/loading.gif" alt="loading image" />
        </div>
      )}
      <div className="space-y-4">
        {allDocuments.map((doc) => (
          <div key={doc.document_id} className="flex items-center">
            <div className="mr-4">
              <Checkbox
                checked={selectedDocumentIds.includes(doc.document_id)}
                onChange={onChangeSelected(doc.document_id)}
              />
            </div>
            <span className="mr-4">{doc.document_name}</span>
            <div className="ml-auto">
              <Button
                variant="icon"
                onClick={removeFromSelected(doc.document_id)}
              >
                <XMarkIcon className="h-5 w-5" />
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default UploadedDocuments;
