import { ArrowUpOnSquareIcon } from '@heroicons/react/24/solid';
import jsPDF from 'jspdf';
import { useRef } from 'react';
import Markdown from 'react-markdown';
import { Button, LawMatrixSpinner } from '../../../ui-base';

function MarkdownRenderer({ content }: { content: string }) {
  return <Markdown>{content}</Markdown>;
}
interface Props {
  generatedResponses: string;
  isFetching: boolean;
}

export function Answers(props: Props) {
  const { generatedResponses, isFetching } = props;
  const markdownRef = useRef<HTMLDivElement>(null);

  const onExport = () => {
    if (markdownRef.current) {
      const pdf = new jsPDF();

      // Convert markdown to HTML
      const htmlContent = markdownRef.current.innerHTML;

      pdf.html(htmlContent, {
        callback: function (pdf) {
          pdf.save('answers.pdf');
        },
        x: 15,
        y: 15,
        width: 170, // slightly narrower than the default page width
        windowWidth: 650, // Adjust this value to match your desired rendering width
      });
    }
  };

  return (
    <div className="flex h-full flex-col rounded-md border">
      <div className="flex items-center justify-between p-2 text-lawmatrix-purple">
        <span>Answers</span>
        <Button
          variant="icon"
          onClick={onExport}
          className="flex gap-2 rounded-full bg-lawmatrix-purple/10 px-4 text-xs"
        >
          <ArrowUpOnSquareIcon className="h-4 w-4" />
          <span>Export</span>
        </Button>
      </div>
      <div className="relative flex-grow overflow-auto bg-gray-100/60 p-2">
        <div className="markdown-content" ref={markdownRef}>
          <Markdown>{generatedResponses}</Markdown>
        </div>
        {isFetching && (
          <div className="mx-auto flex h-full w-20 items-center justify-center">
            <LawMatrixSpinner />
          </div>
        )}
      </div>
      <style>{`
        .markdown-content > * {
          margin-bottom: 1em;
        }
        .markdown-content li {
          display: list-item;
          list-style-type: decimal;
          margin-left: 1.5em;
          margin-bottom: 1em;
        }
      `}</style>
    </div>
  );
}

export default Answers;
