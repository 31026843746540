import { Toaster } from 'react-hot-toast';
import { UserProfileProvider } from '../../providers';
import Assistant from './assistant/Assistant';
import CollapsedNavbar from './navbar/CollapsedNavbar';

export function WorkbenchRoot() {
  return (
    <UserProfileProvider>
      <CollapsedNavbar />
      <Assistant />
      <Toaster />
    </UserProfileProvider>
  );
}
export default WorkbenchRoot;
