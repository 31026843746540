import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

const Home = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect();
  }, []);
  return <div></div>;
  // return (
  //   <>
  //     <Header />
  //     <section className="pt-[24px] xl:pt-[135px]">
  //       <Container className="items-center justify-between space-y-[24px] xl:flex xl:space-y-0">
  //         <div className="basis-1/2">
  //           <h1 className="mb-[18px] text-[40px] font-bold leading-[46px] text-[#1E0E62] xl:mb-[32px] xl:text-[64px] xl:leading-[76.8px]">
  //             Create your{' '}
  //             <span className="text-[#B8A7FB]">
  //               Headnote <br className="hidden xl:block" />
  //               in seconds
  //             </span>
  //           </h1>
  //           <p className="mb-[18px] text-[#7D7D7D] xl:mb-[52px] xl:text-lg">
  //             Lawmatrix uses proprietry AI to generate high quality Headnotes
  //             for any court <br className="hidden xl:block" />
  //             judgement
  //           </p>
  //           <Button onClick={onLogin} variant="primary">
  //             Try now for free
  //           </Button>
  //         </div>
  //         <div className="basis-1/2">
  //           <img
  //             className="ml-auto"
  //             src="/images/common/banner-img.svg"
  //             alt="banner-img"
  //           />
  //         </div>
  //       </Container>
  //     </section>
  //     <section className="py-[70px] xl:pb-[100px] xl:pt-[167px]">
  //       <Container>
  //         <h3 className="mb-[32px] text-center text-2xl font-bold text-[#1E0E62] xl:mb-[80px] xl:text-5xl">
  //           How it Works?
  //         </h3>
  //         <div className="flex flex-col justify-between xl:flex-row xl:items-end xl:justify-between xl:space-y-[30px]">
  //           <div className="max-w-[380px] basis-full xl:basis-1/3">
  //             <div className="relative">
  //               <img
  //                 className="mx-auto mb-[16px] w-[250px] object-contain xl:mb-[30px] xl:h-[230px] xl:w-auto"
  //                 src="/images/common/frame-1.svg"
  //                 alt="frame-1"
  //               />
  //               <img
  //                 className="absolute bottom-[1rem] right-[-3rem] hidden xl:block 2xl:right-[-7rem]"
  //                 src="/images/common/arrow.svg"
  //                 alt="arrow"
  //               />
  //             </div>
  //             <div className="flex space-x-[8px] xl:space-x-[25px]">
  //               <span className="text-base text-[#1D1D1D] xl:text-xl">/1/</span>
  //               <div>
  //                 <h5 className="mb-[8px] text-base font-bold text-[#1E0E62] xl:mb-[16px] xl:text-2xl">
  //                   Upload Your Judgement
  //                 </h5>
  //                 <p className="text-[#7D7D7D]">
  //                   Currently supports pdf, docx, txt files.
  //                 </p>
  //                 <p className="text-[#7D7D7D]">bulk upload (launching soon)</p>
  //               </div>
  //             </div>
  //             <img
  //               className="mx-auto my-[25px] block w-[25px] rotate-[90deg] xl:hidden"
  //               src="/images/common/arrow.svg"
  //               alt="arrow"
  //             />
  //           </div>
  //           <div className="max-w-[380px] basis-1/3">
  //             <div className="relative">
  //               <img
  //                 className="mx-auto mb-[16px] w-[250px] object-contain xl:mb-[30px] xl:h-[230px] xl:w-auto"
  //                 src="/images/common/frame-2.svg"
  //                 alt="frame-2"
  //               />
  //               <img
  //                 className="absolute bottom-[1rem] right-[-3rem] hidden  xl:block 2xl:right-[-7rem]"
  //                 src="/images/common/arrow.svg"
  //                 alt="arrow"
  //               />
  //             </div>
  //             <div className="flex space-x-[8px] xl:space-x-[25px]">
  //               <span className="text-base text-[#1D1D1D] xl:text-xl">/2/</span>
  //               <div>
  //                 <h5 className="mb-[8px] text-base font-bold text-[#1E0E62]  xl:mb-[16px] xl:text-2xl">
  //                   Our AI process it in seconds
  //                 </h5>
  //                 <p className="text-[#7D7D7D]">
  //                   The AI will read through the judgement and <br /> extract
  //                   all the important points of law
  //                 </p>
  //               </div>
  //             </div>
  //             <img
  //               className="mx-auto my-[25px] block w-[25px] rotate-[90deg] xl:hidden"
  //               src="/images/common/arrow.svg"
  //               alt="arrow"
  //             />
  //           </div>
  //           <div className="max-w-[380px] basis-1/3">
  //             <div className="relative">
  //               <img
  //                 className="mx-auto mb-[16px] w-[250px] object-contain xl:mb-[30px] xl:h-[230px] xl:w-auto"
  //                 src="/images/common/frame-3.svg"
  //                 alt="frame-3"
  //               />
  //             </div>
  //             <div className="flex space-x-[8px] xl:space-x-[25px]">
  //               <span className="text-base text-[#1D1D1D] xl:text-xl">/3/</span>
  //               <div>
  //                 <h5 className="mb-[8px] text-base font-bold text-[#1E0E62] xl:mb-[16px] xl:text-2xl">
  //                   View headnotes
  //                 </h5>
  //                 <p className="text-[#7D7D7D]">
  //                   View or download the generated
  //                   <br /> headnote.
  //                 </p>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //         <Button
  //           variant="primary"
  //           onClick={onLogin}
  //           className="mx-auto mt-[75px] hidden xl:block"
  //         >
  //           Try now for free
  //         </Button>
  //       </Container>
  //     </section>
  //     <section className="bg-gradient-to-r from-[#4C417E] to-[#0F0B1F] py-[60px] xl:py-[85px]">
  //       <Container>
  //         <h3 className="mb-[32px] text-center text-2xl font-bold text-white xl:mb-[60px] xl:text-5xl xl:leading-[75px]">
  //           Why Choose LawMatrix AI-Powered Headnote?
  //         </h3>
  //         <div className="grid gap-y-[25px] xl:grid-cols-3 xl:gap-x-[25px] xl:gap-y-[0]">
  //           <div className="rounded-[10px] border-[2.06px] border-[rgba(255,255,255,0.7)] px-[40px] py-[44px] xl:p-[50px]">
  //             <div className="max-w-[330px]">
  //               <img
  //                 className="mb-[24px] xl:mb-[2.5rem]"
  //                 src="/images/common/ic-diamond.svg"
  //                 alt="ic-diamond"
  //               />
  //               <h5 className="mb-[12px] text-base font-bold text-white xl:mb-4 xl:text-2xl">
  //                 High quality
  //               </h5>
  //               <p className="text-[rgba(255,255,255,.7)]">
  //                 Our AI-generated headnotes cover all the points of law, laws
  //                 and sections, cited cases, etc., and are short and simple so
  //                 that lawyers can quickly find what they are looking for.
  //               </p>
  //             </div>
  //           </div>
  //           <div className="rounded-[10px] border-[2.06px] border-[rgba(255,255,255,.7)] px-[40px] py-[44px] xl:p-[50px]">
  //             <div className="max-w-[330px]">
  //               <img
  //                 className="mb-[24px] xl:mb-[2.5rem]"
  //                 src="/images/common/ic-perform.svg"
  //                 alt="ic-perform"
  //               />
  //               <h5 className="mb-[12px] text-base font-bold text-white xl:mb-4 xl:text-2xl">
  //                 Fast
  //               </h5>
  //               <p className="text-[rgba(255,255,255,.7)]">
  //                 Manual headnote creation is very slow and takes many hours.
  //                 Our AI creates them in seconds, saving a lot of time.
  //               </p>
  //             </div>
  //           </div>
  //           <div className="rounded-[10px] border-[2.06px] border-[rgba(255,255,255,.7)] px-[40px] py-[44px] xl:p-[50px]">
  //             <div className="max-w-[330px]">
  //               <img
  //                 className="mb-[24px] xl:mb-[2.5rem]"
  //                 src="/images/common/ic-solar-scale.svg"
  //                 alt="ic-solar-scale"
  //               />
  //               <h5 className="mb-[12px] text-base font-bold text-white xl:mb-4 xl:text-2xl">
  //                 Scalable
  //               </h5>
  //               <p className="text-[rgba(255,255,255,.7)]">
  //                 Whether you need 100, 1000, or hundreds of thousands of
  //                 headnotes, we have you covered.
  //               </p>
  //             </div>
  //           </div>
  //         </div>
  //         <Button
  //           variant="outline"
  //           onClick={onLogin}
  //           className="mx-auto mt-[60px] hidden xl:block"
  //         >
  //           Try now for free
  //         </Button>
  //       </Container>
  //     </section>
  //     <section className="pb-[60px] pt-[40px] xl:pb-[116px] xl:pt-[110px]">
  //       <Container className="relative">
  //         <img
  //           className="absolute bottom-[3.5rem] left-[50%] h-[458px] w-[369px] translate-x-[-50%] xl:bottom-[-116px] xl:left-[unset] xl:right-[-9.5rem] xl:h-auto xl:w-auto"
  //           src="/images/common/question-bg.svg"
  //           alt="question-bg"
  //         />
  //         <div className="mb-[40px] flex items-center justify-center xl:mb-[78px]">
  //           <h3 className="text-center text-2xl font-bold text-[#1E0E62] xl:text-5xl xl:leading-[75px]">
  //             Who is it for?
  //           </h3>
  //         </div>
  //         <div className="grid gap-y-[48px] xl:grid-cols-3 xl:gap-x-[25px] xl:gap-y-[0]">
  //           <div className="max-w-[430px]">
  //             <img
  //               className="mb-[16px] h-[42px] w-[42px] xl:mb-[42px] xl:h-auto xl:w-auto"
  //               src="/images/common/client-1.svg"
  //               alt="client-1"
  //             />
  //             <h5 className="mb-[16px] text-base font-bold text-black xl:mb-[22px] xl:text-2xl">
  //               Law book publishers
  //             </h5>
  //             <p className="text-[#7D7D7D] xl:text-base">
  //               Make AI Headnotes a part of your daily, weekly, and monthly
  //               journals. Boost efficiency by fulfilling a month's requirement
  //               of headnote creation in just one day.
  //             </p>
  //           </div>
  //           <div className="max-w-[430px]">
  //             <img
  //               className="mb-[16px] h-[42px] w-[42px] xl:mb-[42px] xl:h-auto xl:w-auto"
  //               src="/images/common/client-2.svg"
  //               alt="client-2"
  //             />
  //             <h5 className="mb-[16px] text-base font-bold text-black xl:mb-[22px] xl:text-2xl">
  //               Headnote service companies
  //             </h5>
  //             <p className="text-[#7D7D7D] xl:text-base">
  //               Remove the bottleneck of manual headnote creation and serve your
  //               diverse customers swiftly. Scale your business and increase
  //               revenue.
  //             </p>
  //           </div>
  //           <div className="max-w-[430px]">
  //             <img
  //               className="mb-[16px] h-[42px] w-[42px] xl:mb-[42px] xl:h-auto xl:w-auto"
  //               src="/images/common/client-2.svg"
  //               alt="client-2"
  //             />
  //             <h5 className="mb-[16px] text-base font-bold text-black xl:mb-[22px] xl:text-2xl">
  //               Individual headnote creators
  //             </h5>
  //             <p className="text-[#7D7D7D] xl:text-base">
  //               Create headnote using our AI and increase your income by doing
  //               more in less time.
  //             </p>
  //           </div>
  //         </div>
  //         <Button
  //           variant="primary"
  //           onClick={onLogin}
  //           className="mx-auto mt-[75px] !block"
  //         >
  //           Try now for free
  //         </Button>
  //       </Container>
  //     </section>
  //     <section className="bg-[#F4F4FF] py-[40px] xl:bg-[rgba(246,247,254,0.5)] xl:py-[90px]">
  //       <Container>
  //         <h3 className="mb-[32px] text-center text-2xl font-bold text-[#1E0E62] xl:mb-[3.5rem] xl:block xl:text-5xl xl:leading-[75px]">
  //           Simple pricing for everyone's needs
  //         </h3>
  //         <div className="grid gap-y-[16px] lg:grid-cols-3 xl:gap-y-[0]">
  //           <div className="rounded-[16px] border border-[#EBEAED] bg-[#FBFBFF] py-[30px] text-center text-[#1E0E62] xl:border-0 xl:bg-inherit xl:py-0">
  //             <div className="xl:mb-[24px] xl:rounded-tl-[10px] xl:border xl:border-[#EBEAED] xl:bg-[#FBFBFF] xl:py-[63px]">
  //               <h5 className="mb-[15px] text-base xl:mb-[32px] xl:text-[22px] xl:leading-[32px]">
  //                 Free Pack
  //               </h5>
  //               <b className="text-5xl font-bold xl:text-[58px] xl:leading-[70px]">
  //                 <sup className="left-[-5px] top-[-10px] text-base font-normal xl:top-[-25px] xl:text-2xl">
  //                   ₹
  //                 </sup>
  //                 0
  //               </b>
  //               <del className="mb-[10px] ml-3 block  text-xl text-[#A9A4C1] xl:mr-3">
  //                 ₹ 150
  //               </del>
  //               <span className="block">per headnote</span>
  //             </div>
  //             <details className="mt-[15px] block xl:hidden">
  //               <summary>
  //                 <span className="flex text-[#4C417E]">
  //                   See Features{' '}
  //                   <img src="/images/common/arrow-up.svg" alt="arrow-up" />
  //                 </span>
  //               </summary>
  //               <div className="px-2 pt-4">
  //                 <p className="pb-[12px]">Headnotes included - 5 per month</p>
  //                 <p className="pb-[12px]">Validity - 1 month</p>
  //               </div>
  //             </details>
  //             <div className="hidden xl:block">
  //               <p className="pb-[12px]">Headnotes included - 5 per month</p>
  //               <p className="pb-[45px]">Validity - 1 month</p>
  //             </div>
  //             <Button
  //               variant="outline"
  //               onClick={onLogin}
  //               className="mx-auto mt-[20px] block border-[2px] border-[#EBEAED] px-[49px] py-[12px] text-base shadow-[0_1px_5px_0_rgba(108,105,217,0.12),0_2px_2px_0_rgba(130,99,255,0.14),0_3px_1px_-2px_rrgba(145,106,255,0.2)] xl:mt-[32px]"
  //             >
  //               Try now, it's free
  //             </Button>
  //           </div>
  //           <div className="rounded-[16px] border border-[#EBEAED] bg-[#4C417E] py-[30px] text-center text-[#1E0E62] xl:border-0 xl:bg-inherit xl:py-0">
  //             <div className="mb-[24px] text-white xl:border xl:border-[#EBEAED] xl:bg-[#4C417E] xl:py-[63px]">
  //               <h5 className="mb-[15px] text-base xl:mb-[32px] xl:text-[22px] xl:leading-[32px]">
  //                 Preferred Monthly Pack
  //               </h5>
  //               <div className="flex flex-row-reverse flex-col items-end items-center justify-center">
  //                 <b className="block text-5xl font-bold xl:text-[58px] xl:leading-[70px]">
  //                   <sup className="left-[-5px] top-[-10px] text-base font-normal xl:top-[-25px] xl:text-2xl">
  //                     ₹
  //                   </sup>
  //                   89{' '}
  //                   <span className="ml-[-.5rem] text-sm font-normal">
  //                     inc. GST
  //                   </span>
  //                 </b>
  //                 <del className="mb-[10px] ml-3 block text-xl text-[#A9A4C1] xl:mr-3">
  //                   ₹ 150
  //                 </del>
  //                 <span className="block">per headnote</span>
  //               </div>
  //             </div>
  //             <details className="mt-[15px] block xl:hidden">
  //               <summary>
  //                 <span className="flex text-white">
  //                   See Features{' '}
  //                   <img
  //                     className="brightness-[0] invert-[1]"
  //                     src="/images/common/arrow-up.svg"
  //                     alt="arrow-up"
  //                   />
  //                 </span>
  //               </summary>
  //               <div className="px-2 pt-4 text-white">
  //                 <p className="pb-[12px]">Discount - 40%</p>
  //                 <p className="pb-[12px]">Headnotes included - 50</p>
  //                 <p className="pb-[12px]">Validity - 30 days</p>
  //               </div>
  //             </details>
  //             <div className="hidden xl:block">
  //               <p className="pb-[12px]">Discount - 40%</p>
  //               <p className="pb-[12px]">Headnotes included - 50</p>
  //               <p className="pb-[12px]">Validity - 30 days</p>
  //             </div>
  //             <Button
  //               onClick={onLogin}
  //               className="mx-auto mt-[20px] block rounded-[4px] border-[2px] border-[#EBEAED] bg-[#4C417E] px-[49px] py-[12px] text-base text-white shadow-[0_1px_5px_0_rgba(108,105,217,0.12),0_2px_2px_0_rgba(130,99,255,0.14),0_3px_1px_-2px_rrgba(145,106,255,0.2)] xl:mt-[32px]"
  //             >
  //               Start free trial
  //             </Button>
  //           </div>
  //           <div className="rounded-[16px] border border-[#EBEAED] bg-[#FBFBFF] py-[30px] text-center text-[#1E0E62] xl:border-0 xl:bg-inherit xl:py-0">
  //             <div className="xl:mb-[24px] xl:rounded-tr-[10px] xl:border xl:border-[#EBEAED] xl:bg-[#FBFBFF] xl:py-[63px]">
  //               <h5 className="mb-[15px] text-base xl:mb-[32px] xl:text-[22px] xl:leading-[32px]">
  //                 Quarterly Pack
  //               </h5>
  //               <div className="flex flex-row-reverse flex-col items-end items-center justify-center">
  //                 <b className="block text-5xl font-bold xl:text-[58px] xl:leading-[70px]">
  //                   <sup className="left-[-5px] top-[-10px] text-base font-normal xl:top-[-25px] xl:text-2xl">
  //                     ₹
  //                   </sup>
  //                   79{' '}
  //                   <span className="ml-[-.5rem] text-sm font-normal">
  //                     inc. GST
  //                   </span>
  //                 </b>
  //                 <del className="mb-[10px] ml-3 block text-xl text-[#A9A4C1] xl:mr-3">
  //                   ₹ 150
  //                 </del>
  //                 <span className="block">per headnote</span>
  //               </div>
  //             </div>
  //             <details className="mt-[15px] block xl:hidden">
  //               <summary>
  //                 <span className="flex text-[#4C417E]">
  //                   See Features{' '}
  //                   <img src="/images/common/arrow-up.svg" alt="arrow-up" />
  //                 </span>
  //               </summary>
  //               <div className="px-2 pt-4">
  //                 <p className="pb-[12px]">Discount - 47%</p>
  //                 <p className="pb-[12px]">Headnotes included - 1500</p>
  //                 <p className="pb-[12px]">Validity - 90 days</p>
  //               </div>
  //             </details>
  //             <div className="hidden xl:block">
  //               <p className="pb-[12px]">Discount - 47%</p>
  //               <p className="pb-[12px]">Headnotes included - 1500</p>
  //               <p className="pb-[12px]">Validity - 90 days</p>
  //             </div>
  //             <Button
  //               onClick={onLogin}
  //               className="mx-auto mt-[20px] block rounded-[4px] border-[2px] border-[#EBEAED] bg-white px-[49px] py-[12px] text-base text-[#1E0E62] shadow-[0_1px_5px_0_rgba(108,105,217,0.12),0_2px_2px_0_rgba(130,99,255,0.14),0_3px_1px_-2px_rrgba(145,106,255,0.2)] xl:mt-[32px]"
  //             >
  //               Try now for free
  //             </Button>
  //           </div>
  //         </div>
  //       </Container>
  //     </section>
  //     <section className="py-[40px] xl:py-[75px]">
  //       <Container>
  //         <div className="cta-bg rounded-[20px] bg-[#000207] px-[30px] py-[40px] text-white xl:px-[110px] xl:py-[88px]">
  //           <h4 className="text-base xl:text-[32px] xl:leading-[41px]">
  //             Upgrade to the instant AI-powered method for headnote creation
  //             from the slow manual process and fuel your growth.
  //           </h4>
  //           <div className="flex justify-between">
  //             <Button
  //               onClick={onLogin}
  //               className="mt-[24px] block rounded-[4px] bg-white px-[15px] py-[12px] font-bold text-[#1F1938] shadow-[0_1px_5px_0_rgba(108,105,217,0.12),0_2px_2px_0_rgba(130,99,255,0.14),0_3px_1px_-2px_rrgba(145,106,255,0.2)] xl:mt-[4rem]"
  //             >
  //               Try now for free
  //             </Button>
  //             <img
  //               className="mb-[-128px] mr-[-80px] hidden xl:block"
  //               src="/images/common/transition.svg"
  //               alt="transition"
  //             />
  //           </div>
  //         </div>
  //       </Container>
  //     </section>
  //     <Footer />
  //   </>
  // );
};

export default Home;
