import { ArrowDownOnSquareIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import {
  useSubmitPrompt,
  useUploadDocumentForAssitant,
} from '../../../data-access';
import {
  PromptHistory as PromptHistoryType,
  PromptResponse,
  UploadDocumentForAssistant,
} from '../../../types';
import { Button } from '../../../ui-base';
import { useUser } from '../../../util';
import Answers from './Answers';
import PromptEditor from './PromptEditor';
import PromptHistory from './PromptHistory';
import UploadedDocuments from './UploadedDocuments';

export function Assistant() {
  const [uploadedDocuments, setUploadedDocuments] = useState<
    UploadDocumentForAssistant[]
  >([]);
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<string[]>([]);
  const [showDocumentSection, setShowDocumentSection] = useState(false);
  const [promptResponse, setPromptResponse] = useState<string>('');
  const [currentPromptId, setCurrentPromptId] = useState('');
  const [selectedPrompt, setSelectPrompt] = useState<
    PromptHistoryType | undefined
  >(undefined);

  const { userId = '' } = useUser();

  const onDocUploadSuccess = (data: UploadDocumentForAssistant) => {
    setUploadedDocuments((prev) => [...prev, data]);
    setSelectedDocumentIds((prev) => [...prev, data.document_id]);
  };

  const onSubmitPromptSuccess = (val: PromptResponse) => {
    setPromptResponse(val.prompt_response);
    setCurrentPromptId(val.id);
  };

  const { mutate: uploadDocMutate, isPending } = useUploadDocumentForAssitant(
    userId,
    onDocUploadSuccess,
  );

  const { mutate: submitPromptMutate, isPending: isSubmitInProgress } =
    useSubmitPrompt(userId, onSubmitPromptSuccess);

  const onRunPrompt = (val: string) => {
    setPromptResponse('');
    submitPromptMutate({
      document_ids: selectedDocumentIds,
      prompt: val,
    });
  };

  const handleFileDrop = (
    event:
      | React.DragEvent<HTMLDivElement>
      | React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files =
      event.type === 'drop'
        ? (event as React.DragEvent<HTMLDivElement>).dataTransfer.files
        : (event as React.ChangeEvent<HTMLInputElement>).target.files;
    if (files) {
      const formData = new FormData();
      formData.append('file', files[0] || '');
      uploadDocMutate(formData);
    }
    setShowDocumentSection(true);
  };

  const onChangeSelectedDocumentIds = (ids: string[]) =>
    setSelectedDocumentIds(ids);

  const onSelectPromptFromHistory = (prompt: PromptHistoryType) => {
    setSelectPrompt(prompt);
    setCurrentPromptId(prompt.id);
  };

  return (
    <div className="fixed bottom-0 left-20 right-0 top-0 flex flex-col overflow-hidden p-4">
      <div id="header" className="my-1 mb-4">
        <h2 className="text-xl font-bold text-lawmatrix-purple">Assistant</h2>
      </div>
      <div className="flex w-full flex-grow overflow-hidden">
        <div className="grid w-full grid-cols-2 grid-rows-2 gap-2">
          <PromptEditor
            onRunPrompt={onRunPrompt}
            promptId={currentPromptId}
            userId={userId}
            savedPromptDetails={selectedPrompt}
          />
          <div className="row-span-2">
            <Answers
              generatedResponses={promptResponse}
              isFetching={isSubmitInProgress}
            />
          </div>
          <div className="flex h-full flex-col justify-between gap-2">
            <div className="flex h-full flex-grow gap-2">
              <PromptHistory onSelectPrompt={onSelectPromptFromHistory} />
              {showDocumentSection && (
                <UploadedDocuments
                  allDocuments={uploadedDocuments}
                  isUploading={isPending}
                  onChangeSelectedIds={onChangeSelectedDocumentIds}
                  selectedDocumentIds={selectedDocumentIds}
                />
              )}
            </div>
            <div className="flex justify-end">
              <Button
                variant="icon"
                className="relative flex items-center gap-2 rounded-full bg-lawmatrix-purple/10 px-4 text-xs"
              >
                <ArrowDownOnSquareIcon className="h-4 w-4" />
                <span>Upload document</span>
                <input
                  type="file"
                  accept=".pdf,.txt"
                  onChange={handleFileDrop}
                  className="absolute inset-0 cursor-pointer opacity-0"
                />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Assistant;
