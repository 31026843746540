import { useEffect, useState } from 'react';
import { useSavePrompt } from '../../../data-access';
import { PromptHistory } from '../../../types';
import { Button, Input, Textarea } from '../../../ui-base';
import { toast } from '../../../util';

interface Props {
  onRunPrompt: (val: string) => void;
  userId: string;
  promptId: string;
  savedPromptDetails?: PromptHistory;
}

export function PromptEditor(props: Props) {
  const { onRunPrompt, userId, promptId, savedPromptDetails } = props;
  const [promptValue, setPromptValue] = useState('');
  const [promptAlias, setPrommptAlias] = useState('');

  const onSuccessSavePrompt = () => {
    toast('Prompt saved successfully');
  };

  const { mutate: savePromptMutate } = useSavePrompt(
    userId,
    promptId,
    onSuccessSavePrompt,
  );

  useEffect(() => {
    setPromptValue(savedPromptDetails?.prompt_query.prompt || '');
    setPrommptAlias(savedPromptDetails?.alias || '');
  }, [savedPromptDetails]);

  const onRun = () => {
    onRunPrompt(promptValue);
  };
  const onChangePromptValue = (val: string) => setPromptValue(val);
  const onAliasChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setPrommptAlias(e.target.value);

  const onSavePrompt = () => {
    if (promptId === '') {
      toast('You should run the prompt first and then save it', {
        type: 'warning',
      });
      return;
    }
    savePromptMutate({ alias: promptAlias, is_saved: true });
  };

  return (
    <div className="flex h-full flex-col rounded-md border">
      <div className="flex items-center justify-between p-2 text-lawmatrix-purple">
        <Input
          className="rounded-md border p-1"
          value={promptAlias}
          placeholder="Untitled"
          onChange={onAliasChange}
        />
        <div className="flex gap-2">
          <Button
            onClick={onSavePrompt}
            size="small"
            className="text-sm"
            disabled={promptAlias === ''}
          >
            Save
          </Button>

          <Button
            variant="primary"
            onClick={onRun}
            size="small"
            className="text-sm"
            disabled={promptValue === ''}
          >
            Run
          </Button>
        </div>
      </div>
      <Textarea
        className="flex-grow resize-none bg-gray-100/60 p-2"
        value={promptValue}
        onChange={onChangePromptValue}
        placeholder="Ask anything related to documents you uploaded..."
      />
    </div>
  );
}

export default PromptEditor;
