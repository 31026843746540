export const Input = (props: React.ComponentProps<'input'>) => {
  return (
    <input 
      role="textbox"
      type={props?.type}
      placeholder={props?.placeholder}
      className={props?.className}
      autoComplete={props?.autoComplete}
      name={props?.name}
      onChange={props?.onChange}
      onClick={props?.onClick}
      value={props?.value}
    />
  );
};

export default Input;
