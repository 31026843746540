import { PromptResponse, SubmitPromptRequest } from '../../types';
import useAuthenticatedMutation from '../shared/useAuthenticatedMutation';

export function useSubmitPrompt(
  userId: string,
  onSuccess: (data: PromptResponse) => void,
) {
  const url = `/api/v1/users/${userId}/prompts`;

  const request = new Request(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
  });

  return useAuthenticatedMutation<PromptResponse, SubmitPromptRequest>(
    request,
    {
      onSuccess,
      onSuccessInvalidationKeys: [['prompts']],
    },
  );
}

export default useSubmitPrompt;
