import {
  ArrowRightStartOnRectangleIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { Button } from '../../../ui-base';
import { useAuthUtils, useUser } from '../../../util';
import CollapsedLinks from './CollapsedLinks';
import { navItems } from './util';

export function CollapsedNavbar() {
  const { picture } = useUser();
  const { logout } = useAuthUtils();

  const onLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };
  return (
    <div className="fixed bottom-0 left-0 top-0 flex w-20 flex-col justify-between border-r-2 border-gray-300 ">
      <div>
        <Link to="/workbench" className="my-2 flex w-full justify-center">
          <img src="/lmicon.png" />
          {/* <LawMatrixIcon width={'100px'} className="h-[200px] w-[200px]" /> */}
        </Link>
        <div className="mt-8">
          <CollapsedLinks items={navItems} />
        </div>
      </div>
      <div className="flex flex-col items-center gap-1 py-2">
        {picture ? (
          <img
            className="h-8 w-8 rounded-full text-center"
            src={picture}
            alt="user avatar"
          />
        ) : (
          <UserCircleIcon className="h-5 w-5" />
        )}
        <Button
          variant="icon"
          onClick={onLogout}
          className="flex flex-col justify-center text-xs"
        >
          <ArrowRightStartOnRectangleIcon className="h-5 w-5" />
          <span>Logout</span>
        </Button>
      </div>
    </div>
  );
}

export default CollapsedNavbar;
